.App {
  text-align: center;
}
html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Poppins', sans-serif;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.largeradius1 {
  border-radius: 50px;
}
.largeradius2 {
  border-radius: 25px;
}


.hero-shadow-box {
  z-index: 1;
  /* background-image: linear-gradient(249deg, rgba(145, 160, 6, 0.169), rgba(0, 255, 98, 0.069)); */
  /* background-image: linear(249deg, rgba(60, 134, 213, 0.46), rgba(8, 13, 159, 0.389)); */
  background-image: radial-gradient(circle, rgba(60, 134, 213, 0.46), rgba(8, 13, 159, 0.389));
  filter: blur(250px);
  border-radius: 100%;
  width: 600px;
  height: 500px;
  position: absolute;
  top: -30%;
  bottom: auto;
  left: -10%;
  right: auto;
}
.hero-shadow-box1 {
  z-index: 1;
  /* background-image: linear-gradient(249deg, rgba(145, 160, 6, 0.169), rgba(0, 255, 98, 0.069)); */
  /* background-image: linear(249deg, rgba(60, 134, 213, 0.46), rgba(8, 13, 159, 0.389)); */
  background-image: radial-gradient(circle, rgba(60, 134, 213, 0.46), rgba(29, 97, 223, 0.605));
  filter: blur(250px);
  border-radius: 100%;
  width: 600px;
  height: 500px;
  position: absolute;
  top: auto;
  bottom: 30%;
  left: auto;
  right: -10%;
}
.wave {
  width: w-screen;
}

/* .heroheader::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  background-image: url('../public/wavehaikei.svg'); 
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1; 
} */

.heroimage {
  z-index: 2;
}

.banner-section {
  padding-top: 16px;
  padding-bottom: 140px;
  position: relative;
  overflow: hidden;
}

/* .heroheader {
  height: 600px;
  background-image: 
    linear-gradient(rgba(0, 0, 0, 0.847), rgba(0, 0, 0, 0.31)), 
    url('../public/ropepull.jpg'); 
  background-size: cover;
  background-repeat: no-repeat;

  background-position: center;
} */

.navbar1 {
  /* background-color: rgba(204, 204, 204, 0.733); Semi-transparent background */
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: saturate(180%) blur(1px); /* Blur effect */
  -webkit-backdrop-filter: blur(2px); /* For Safari support */
  /* border-radius: 10px; */
  padding: 10px 20px; /* Padding around the content */
  display: inline-block; /* Adjust display as needed */
  /* box-shadow: 0 0 1rem 0 rgba(0, 0, 0, .2); */
}

.navbar1 ul {
  list-style: none; /* Remove bullet points */
  margin: 0;
  padding: 0;
  display: flex; /* Flexbox for horizontal alignment */
  gap: 20px; /* Spacing between items */
}



.temphei {
  height: 90vh;
} 

/* .coach2i {
  background-image: 
    url('../public/treadmill.jpg'); 
    background-size: cover;
    background-repeat: no-repeat;
  
    background-position: center;
} */
.coach3i {
  background-image: 
    url('../public/kbell.jpg'); 
    background-size: cover;
    background-repeat: no-repeat;
  
    background-position: center;
}

.tester {
  font-family: 'Poppins', sans-serif;
}

.zebra {
  margin-top: -30px;
}



.fullimage {
  height: 500px;
   /* linear-gradient(rgba(53, 161, 228, 0.321), rgba(85, 151, 232, 0.407)), */
  background-image:url('../public/ropepullcut.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  
}

.oncoach {
  height: 700px;
}


/* 
ScrollingBanner.css
@keyframes scroll {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}



.animate-scroll {
  @apply pl-[100%] inline-block;
  animation: scroll 60s linear infinite;
} */

/* ScrollingBanner.css */
/* @keyframes scroll-reverse {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
} */

/* ScrollingBanner.css */
@keyframes scroll-reverse {
  0% {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-58%);
  }

  100% {
    transform: translateX(0); 
  }

}

.animate-scroll {
  @apply inline-block;
  animation: scroll-reverse 60s linear infinite; /* Adjust timing as needed ease-in-out  */
}

/* .hero {
  height: 100vh;
} */

/* .hero {
  width: 100%;
  height: 100vh; 
  background-image: 
    linear-gradient(rgba(0, 0, 0, 0.847), rgba(0, 0, 0, 0.31)), 
    url('../public/ropepull.jpg'); 
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; 
  position: relative; 
} */

.orange {
  height: 80vh;
  
}


/* / */
.heroh1 {
  line-height: 50px !important;
}


.heroimage {
  max-width: 43%;
  max-height: 620px;
}

.herotext {
  max-width: 55%;
  margin-right: 20px;
}

@media (max-width: 767px) {
  .herotext {
    /* Your styles for small screens */
    max-width: 100%; 
    margin-right: 10px; 
  }
  .heroimage {
    max-width: 100%;
    max-height: 620px;
  }
  .zebra {
    margin-top: 0;
  }
  .heroh1 {
    line-height: 60px !important;
  }
  
}
.max-w-10 {
  max-width: 10%;
}

.max-w-20 {
  max-width: 20%;
}

.max-w-30 {
  max-width: 30%;
}

.max-w-40 {
  max-width: 40%;
}

.max-w-50 {
  max-width: 50%;
}

.max-w-60 {
  max-width: 60%;
}

.max-w-70 {
  max-width: 70%;
}

.max-w-80 {
  max-width: 80%;
}

.max-w-90 {
  max-width: 90%;
}

.max-w-100 {
  max-width: 100%;
}

.resultimage {
  /* max-height: 400px; */
}